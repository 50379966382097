import { Provider } from '@angular/core';
import { PRODUCT_PROMOTIONS_ROUTE_PATH } from 'product-promotions-data';
import { provideRoutes } from 'routing-util';
import { ProductPromotionsFeatureRoutesConfig, defaultProductPromotionsFeatureRoutesConfig } from './ProductPromotionsFeatureRoutesConfig';

export function provideProductPromotionsFeatureRoutes(config?: Partial<ProductPromotionsFeatureRoutesConfig>): Provider {
  const useConfig = { ...defaultProductPromotionsFeatureRoutesConfig, ...config };
  return [
    {
      provide: PRODUCT_PROMOTIONS_ROUTE_PATH,
      useValue: useConfig.path,
    },
    provideRoutes([
      {
        path: useConfig.path,
        loadChildren: () => import('./routes/routes').then((m) => m.routes),
        // data: {
        //   navigationId: 'app.main-menu/product-promotions',
        // },
      },
    ]),
  ];
}
