import { ChangeDetectionStrategy, Component, booleanAttribute, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatBadge } from '@angular/material/badge';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { CartService } from 'lib-cart';
import { map } from 'rxjs';

@Component({
  selector: 'button[ideal-cart-menu-button]',
  standalone: true,
  imports: [MatRipple, MatIcon, MatBadge],
  templateUrl: './cart-menu-button.component.html',
  styleUrl: './cart-menu-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.open]': 'open() ? "" : null',
  },
})
export class CartMenuButtonComponent {
  #cartService = inject(CartService);
  public readonly open = input(false, { transform: booleanAttribute });
  public readonly itemCount = toSignal(this.#cartService.itemsChange.pipe(map((items) => items.length)), { initialValue: 0 });
}
