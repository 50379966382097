import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, effect, inject, signal } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationError, Router, RoutesRecognized } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LegacyLoadingIndicatorService {
  readonly #document = inject(DOCUMENT);
  readonly #router = inject(Router);
  readonly #showLegacyLoadingIndicator = signal(false);
  readonly #isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  readonly showLegacyLoadingIndicator = this.#showLegacyLoadingIndicator.asReadonly();

  #useLegacyLoadIndicator(route: ActivatedRouteSnapshot | null) {
    if (route) {
      if (route.data['legacyLoadIndicator'] === true) {
        return true;
      }
      for (const c of route.children) {
        if (this.#useLegacyLoadIndicator(c)) {
          return true;
        }
      }
    }
    return false;
  }

  initialize() {
    if (this.#isBrowser) {
      this.#router.events.subscribe((event) => {
        if (event instanceof RoutesRecognized && this.#useLegacyLoadIndicator(event.state.root)) {
          this.#showLegacyLoadingIndicator.set(true);
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.#showLegacyLoadingIndicator.set(false);
        }
      });
    }
  }

  constructor() {
    effect(() => {
      const showLegacyLoadingIndicator = this.showLegacyLoadingIndicator();
      if (showLegacyLoadingIndicator) {
        this.#document.body.classList.add('legacy-loading');
      } else {
        this.#document.body.classList.remove('legacy-loading');
      }
    });
  }
}
