import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ProductFastSearchResults, ProductQuickSearchStore } from 'product-quick-search-data';
import { ProductQuickSearchItemComponent } from '../product-quick-search-item';
import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { DrawerComponent } from 'common-ui';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { delay, map } from 'rxjs';

@Component({
  selector: 'ideal-product-quick-search-results',
  standalone: true,
  imports: [MatNavList, MatListItem, RouterLink, ProductQuickSearchItemComponent, MatProgressSpinnerModule, NgTemplateOutlet],
  templateUrl: './product-quick-search-results.component.html',
  styleUrl: './product-quick-search-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('growResults', [
      state('void', style({ height: '0px', overflow: 'hidden' })),
      state('true', style({ height: '*' })),
      transition(':enter', [
        query('mat-nav-list', [style({ position: 'absolute', bottom: '0px', left: '0px', right: '0px' })]),
        animate('30s ease-in-out'),
      ]),
    ]),
  ],
})
export class ProductQuickSearchResultsComponent {
  private readonly _store = inject(ProductQuickSearchStore);

  public readonly searchResults = input.required<ProductFastSearchResults>();
  public readonly searchTerm = input.required<string>();

  protected readonly byPartNumber = computed(() => this.searchResults().byPartNumber);
  protected readonly byDescription = computed(() => this.searchResults().byDescription);
  protected readonly byKeyword = computed(() => this.searchResults().byKeyword);
  protected readonly byCrossReference = computed(() => this.searchResults().byCrossReference);

  protected readonly loading = computed(() => this._store.loading());
  protected readonly byPartNumberLoading = computed(() => this.loading().byPartNumber);
  protected readonly byDescriptionLoading = computed(() => this.loading().byDescription);
  protected readonly byKeywordLoading = computed(() => this.loading().byKeyword);
  protected readonly byCrossReferenceLoading = computed(() => this.loading().byCrossReference);

  protected readonly hasCrossReferences = computed(() => this._store.hasCrossReferences());

  protected readonly showResultsByPartNumber = toSignal(
    toObservable(this.searchResults).pipe(
      delay(250),
      map((a) => a.byPartNumber.length > 0),
    ),
    { initialValue: false },
  );
  protected readonly showResultsByDescription = toSignal(
    toObservable(this.searchResults).pipe(
      delay(250),
      map((a) => a.byDescription.length > 0),
    ),
    { initialValue: false },
  );
  protected readonly showResultsByKeyword = toSignal(
    toObservable(this.searchResults).pipe(
      delay(250),
      map((a) => a.byKeyword.length > 0),
    ),
    { initialValue: false },
  );
  protected readonly showResultsByCrossReference = toSignal(
    toObservable(this.searchResults).pipe(
      delay(250),
      map((a) => a.byCrossReference.length > 0),
    ),
    { initialValue: false },
  );

  protected isOpen(drawer: DrawerComponent, resultCount: number) {
    console.log('isOpen');
    return true;
  }
}
