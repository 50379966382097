import { computed, inject } from '@angular/core';
import { ProductInventoryStockLocation, StockApi } from '@idealsupply/ngclient-webservice-inventory';
import { patchState, signalStore, type, withMethods } from '@ngrx/signals';
import { entityConfig, setEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { bufferedDebounceTime } from 'common-util';
import { Subject } from 'rxjs';

interface ProductInventoryStockLocationEntity {
  update: Date | null;
  data: ProductInventoryStockLocation;
}

const ProductInventoryStockLocationEntityConfig = entityConfig({
  entity: type<ProductInventoryStockLocationEntity>(),
  collection: '_stockLocation',
  selectId: (product) => product.data.lineCode + product.data.partNumber,
});

export const ProductInventoryStockLocationStore = signalStore(
  { providedIn: 'root' },
  withEntities(ProductInventoryStockLocationEntityConfig),
  withMethods((store) => {
    const stockApi = inject(StockApi);
    function loadStockLocations(ids: { lineCode: string; partNumber: string }[]) {
      const valueMap = new Map();
      ids
        .filter((id) => {
          const entity = store._stockLocationEntityMap()[id.lineCode + id.partNumber];
          if (!entity || new Date().getTime() - (entity.update?.getTime() ?? 0) > 1000 * 60 * 5) {
            return true;
          }
          return false;
        })
        .forEach((id) => {
          valueMap.set(id.lineCode + id.partNumber, { lineCode: id.lineCode, partNumber: id.partNumber });
        });

      const idsToFetch = [...valueMap.values()] as { lineCode: string; partNumber: string }[];
      const dummies = idsToFetch.map(
        (id) =>
          ({
            update: null,
            data: {
              lineCode: id.lineCode,
              partNumber: id.partNumber,
              stockLocation: null,
            },
          }) as ProductInventoryStockLocationEntity,
      );
      patchState(store, setEntities(dummies, ProductInventoryStockLocationEntityConfig));
      stockApi.getStockLocationsForProducts(idsToFetch).subscribe((stockLocations) => {
        const now = new Date();
        stockLocations
          .map(
            (stockLocation) =>
              ({
                update: now,
                data: stockLocation,
              }) as ProductInventoryStockLocationEntity,
          )
          .forEach((entity) => {
            patchState(
              store,
              updateEntity(
                {
                  id: entity.data.lineCode + entity.data.partNumber,
                  changes: { update: entity.update, data: entity.data },
                },
                ProductInventoryStockLocationEntityConfig,
              ),
            );
          });
      });
    }
    const loadStockLocations$$ = new Subject<{ lineCode: string; partNumber: string }>();
    loadStockLocations$$.pipe(bufferedDebounceTime(5)).subscribe(loadStockLocations);

    return {
      loadStockLocation: (id: { lineCode: string; partNumber: string }) => {
        loadStockLocations$$.next(id);
      },
      getStockLocation: (id: { lineCode: string; partNumber: string }) => {
        return computed(() => store._stockLocationEntityMap()[id.lineCode + id.partNumber]);
      },
    };
  }),
);
