import { Component } from '@angular/core';
import { AppShellComponent } from 'app-shell-ui';

@Component({
  selector: 'ideal-root',
  standalone: true,
  imports: [AppShellComponent],
  template: `<ideal-app-shell />`,
  styles: `
    :host {
      display: block;
      height: 100%;
    }
  `,
})
export class AppComponent {}
