@let type = resultType();

<ideal-image [src]="imageSrc()"></ideal-image>
<div
  part-number
  i18n-label="@@inventory.quick-search.part-number.label"
  label="Part Number"
  i18n-short-label="@@inventory.quick-search.part-number.short-label"
  short-label="pn"
  [innerHtml]="partNumberMatched()"
></div>
<div
  description
  i18n-label="@@inventory.quick-search.description.label"
  label="Description"
  i18n-short-label="@@inventory.quick-search.description.short-label"
  short-label="desc"
  [innerHtml]="descriptionMatched()"
></div>

@if (type === "keyword") {
  <div
    keyword-list
    i18n-label="@@inventory.quick-search.keywords.label"
    label="Keywords"
    i18n-short-label="@@inventory.quick-search.keywords.short-label"
    short-label="kw"
  >
    @for (item of keywordsMatched(); track $index) {
      <span keyword [innerHtml]="item"></span>
    }
  </div>
}
@if (type === "cross-reference") {
  <div
    cross-reference-list
    i18n-label="@@inventory.quick-search.cross-references.label"
    label="Cross References"
    i18n-short-label="@@inventory.quick-search.cross-references.short-label"
    short-label="refs"
  >
    @for (item of crossRefsMatched(); track $index) {
      <span cross-reference [innerHtml]="item"></span>
    }
  </div>
}

<div stock-container>
  <div *withLoadingPlaceholder="{ loading: stock() === null, height: 'var(--stock-height)' }">
    @if (stockLabel()) {
      <div stock [attr.stock-location]="stock() || 'available'">
        <label>
          {{ stockLabel() }}
        </label>
      </div>
    }
  </div>
</div>

<!-- {{ resultType() }} | {{ prod.partNumber }} | {{ prod.description }} |  | -->

<!-- <div class="product">
  <div class="image-container">
    <div class="image-border">
      @if (product().imageUrl?.trim()) {
        <img [ngSrc]="product().imageUrl! + ''" fill (load)="onImageLoaded($event)" (error)="onImageError($event)" />
      } @else {
        <img class="placeholder" src="assets/logos/link.svg" />
      }
    </div>
  </div>
  <div class="info-container">
    <div>
      <label>
        <span [innerHtml]="makeMatchedString(product()) + ' | '"></span>
        <ng-container>
          {{ product().partNumber }}
        </ng-container>
      </label>

      @switch (product().stockLocation) {
        @case (StockLocationType.InStock) {
          <label class="stock-location in-stock">In Warehouse</label>
        }
        @case (StockLocationType.InWarehouse) {
          <label class="stock-location in-warehouse">In Stock</label>
        }
        @case (StockLocationType.OnBackOrder) {
          <label class="stock-location on-back-order">On Back Order</label>
        }
      }
    </div>
    <div>
      <label>{{ product().description }}</label>
    </div>
  </div>
</div> -->
