import { Injectable, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SearchService, SearchServiceState } from 'app-shell-data';

import { ProductFastSearchResults, ProductQuickSearchStore } from 'product-quick-search-data';
import { tap } from 'rxjs';

@Injectable()
export class ProductQuickSearchService implements SearchService<ProductFastSearchResults> {
  private readonly _store = inject(ProductQuickSearchStore);
  public readonly searchQuery = this._store.filter;
  public readonly searchResults = this._store.search;
  private readonly searchResults$ = toObservable(this._store.search);

  public readonly searchResultCount = computed(() => {
    const results = this.searchResults();
    return (
      (results.byCrossReference?.length ?? 0) +
      (results.byDescription?.length ?? 0) +
      (results.byKeyword?.length ?? 0) +
      (results.byPartNumber?.length ?? 0)
    );
  });

  public readonly searchState = signal(SearchServiceState.Idle).asReadonly();
  public readonly searchError = signal<any>(undefined).asReadonly();

  public search(value: string) {
    this._store.setFilter(value);
  }

  // public readonly searchState = computed(() => {
  //   const a  = this._store.loading();
  //   return a ? SearchServiceState.Loading : SearchServiceState.Success;
  // });

  // private readonly _state = signal(SearchServiceState.Idle);
  // private readonly _error = signal<any>(undefined);
  // private readonly _searchQuery$$ = new BehaviorSubject<string>('');

  // private readonly _searchResults$ = this._searchQuery$$
  //   .pipe(
  //     tap(() => {
  //       if (this._state() === SearchServiceState.Idle) {
  //         this._state.set(SearchServiceState.Typing);
  //       }
  //     }),
  //     map((value) => value.trim()),
  //     distinctUntilChanged(),
  //     debounceTime(300),
  //     tap(() => {
  //       this._state.set(SearchServiceState.Loading);
  //     }),
  //     switchMap((value) => (!!value ? this._shoppingService.quickSearch(value) : of<ProductQuickSearchListItem[]>([]))),
  //     tap((value: ProductQuickSearchListItem[]) => {
  //       this._state.set(value?.length ? SearchServiceState.Success : SearchServiceState.Idle);
  //       this._error.set(undefined);
  //     }),
  //     catchError((e) => {
  //       this._error.set(e);
  //       this._state.set(SearchServiceState.Error);
  //       return of<ProductQuickSearchListItem[]>([]);
  //     }),
  //   )
  //   .pipe(share(), takeUntilDestroyed());

  // public readonly searchState = this._state.asReadonly();
  // public readonly searchError = this._error.asReadonly();
  // public readonly searchQuery = toSignal(this._searchQuery$$, { initialValue: '' });
  // public readonly searchResults = toSignal(this._searchResults$, { initialValue: [] });
  // public readonly searchResultCount = computed(() => this.searchResults().length);

  // public async search(value: string) {
  //   this._searchQuery$$.next(value);
  //   return firstValueFrom(this._searchResults$);
  // }
}
