import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { ApplicationService } from 'app-shell-data';
import { AppSideDrawerDirective, RenderInAppActionsMainDirective } from 'app-shell-ui';
import { CartMenuButtonComponent, CartMenuComponent } from 'cart-ui';

@Component({
  selector: 'ideal-import-cart-menu',
  standalone: true,
  imports: [AppSideDrawerDirective, CartMenuButtonComponent, CartMenuComponent, RenderInAppActionsMainDirective],
  templateUrl: './import-cart-menu.component.html',
  styleUrl: './import-cart-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportCartMenuComponent {
  private readonly _store = inject(ApplicationService);

  protected readonly isOpen = computed(() => this._store.sideDrawers.openedDrawerId() === 'cart');

  protected toggleCart() {
    this._store.sideDrawers.toggle('cart');
  }
}
