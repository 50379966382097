import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartPanelComponent } from 'lib-cart';

@Component({
  selector: 'ideal-cart-menu',
  standalone: true,
  imports: [CartPanelComponent],
  templateUrl: './cart-menu.component.html',
  styleUrl: './cart-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartMenuComponent {}
