import { bootstrapApplication } from '@angular/platform-browser';
import { initializeApplication } from 'app-shell-data';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { ThemeMode, setThemeMode } from '@cpangular/ng-material-theme';

// TODO: check if still needed
setThemeMode(ThemeMode.LIGHT);

initializeApplication(environment(), appConfig)
  .then(() => bootstrapApplication(AppComponent, appConfig))
  .catch((err) => console.error(err));
