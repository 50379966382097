import { Provider } from '@angular/core';
import { provideRoutes } from 'routing-util';
import { VENDORS_ROUTE_PATH } from 'vendors-data';
import { VendorsFeatureRoutesConfig, defaultVendorsFeatureRoutesConfig } from './VendorsFeatureRoutesConfig';

export function provideVendorsFeatureRoutes(config?: Partial<VendorsFeatureRoutesConfig>): Provider {
  const useConfig = { ...defaultVendorsFeatureRoutesConfig, ...config };
  return [
    {
      provide: VENDORS_ROUTE_PATH,
      useValue: useConfig.path,
    },
    provideRoutes([
      {
        path: useConfig.path,
        loadChildren: () => import('./routes/routes').then((m) => m.routes),
      },
    ]),
  ];
}
