import { APP_CONFIG, APP_VERSION, AppConfig, AppEnvironment, AuthenticationConfig, loadAppConfig } from 'config-data';

export const environment = () => {
  const loaded = loadAppConfig<AppConfig>() ?? ({} as AppConfig);

  console.info('Loaded config from dom', loaded);

  loaded.appVersion ??= '0.0.0';

  loaded.auth ??= {} as AuthenticationConfig;
  loaded.auth.apiRoot ??= 'https://api.idealsupply.com';
  loaded.auth.stsAuthority ??= 'https://auth.idealsupply.com';
  loaded.auth.registerUrl ??= `${loaded.auth.stsAuthority}/signup`;
  loaded.auth.clientId ??= 'ideallink';
  loaded.auth.clientScope ??= 'openid customer';

  loaded.apiRewrites ??= {
    'https://api.idealsupply.com': loaded.auth.apiRoot,
    'https://auth.idealsupply.com': loaded.auth.stsAuthority,
  };

  console.info('Loaded applying config to environment', loaded);

  return {
    env: 'production',
    ...loaded,
    providers: [
      {
        provide: APP_CONFIG,
        useValue: loaded,
      },
      {
        provide: APP_VERSION,
        useValue: loaded.appVersion,
      },
    ],
  } as AppEnvironment;
};
