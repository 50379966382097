import { APP_INITIALIZER, Provider, computed, inject, signal } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { AuthenticationService } from 'authentication-data';
import { REQUEST_QUOTE_MESSAGE_GROUP_ID } from 'lib-quotes';
import { NavItemType, provideNavItems } from 'navigation-data';
import { ImportLegacyFeaturesConfig } from './ImportLegacyFeaturesConfig';
import { LegacyLoadingIndicatorService } from './LegacyLoadingIndicatorService';
import { defaultImportLegacyFeaturesConfig } from './defaultImportLegacyFeaturesConfig';
import { provideLegacyFeatureRoutes } from './import-legacy-feature.routes';

export function provideImportLegacyFeatures(config?: Partial<ImportLegacyFeaturesConfig>): Provider {
  const useConfig = { ...defaultImportLegacyFeaturesConfig, ...config };
  return [
    provideLegacyCustomerProfileFeature(),
    provideLegacyProductsFeature(),
    provideLegacyPromotionsFeature(),
    // provideLegacyShopFeature(),
    provideLegacyOrdersFeature(),
    provideLegacyQuotesFeature(),
    provideLegacyCartFeature(),
    provideLegacyFeatureRoutes(useConfig),
  ];
}

export function provideLegacyLoadingIndicatorFeature(): Provider {
  return [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LegacyLoadingIndicatorService],
      useFactory: (i: LegacyLoadingIndicatorService) => {
        return () => {
          i.initialize();
          return i;
        };
      },
    },
  ];
}

export function provideLegacyCustomerProfileFeature(): Provider {
  return [];
}

export function provideLegacyProductsFeature(): Provider {
  return [
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/products',
      label: signal($localize`:@@MainMenu_ProductsTitle:Products`),
      icon: signal('inventory_2'),
      priority: signal(100),
      command: signal([`/products`]),
    }),
  ];
}

export function provideLegacyPromotionsFeature(): Provider {
  return [
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/promotions',
      label: signal($localize`:@@MainMenu_PromotionsTitle:Promotions`),
      icon: signal('sell'),
      priority: signal(90),
      command: signal([`/promotions`]),
    }),
  ];
}

// export function provideLegacyShopFeature(): Provider {
//   return [
//     provideNavItems({
//       type: NavItemType.RouteLink,
//       id: 'app.main-menu/vendors',
//       label: signal($localize`:@@MainMenu_VendorsTitle:Vendors`),
//       icon: signal('storefront'),
//       priority: signal(80),
//       command: signal([`/vendors`]),
//     }),
//   ];
// }

export function provideLegacyOrdersFeature(): Provider {
  return [
    provideNativeDateAdapter(),
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/orders',
      label: signal($localize`:@@MainMenu_OrdersTitle:Orders`),
      icon: signal('receipt_long'),
      priority: signal(70),
      command: signal([`/orders`]),
      hidden: () => {
        const auth = inject(AuthenticationService);
        return computed(() => !auth.isAuthenticated());
      },
    }),
  ];
}

export function provideLegacyQuotesFeature(): Provider {
  return [
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/quotes',
      label: signal($localize`:@@MainMenu_QuotesTitle:Quotes`),
      icon: signal('request_quote'),
      priority: signal(60),
      command: signal([`/quotes`]),
      hidden: () => {
        const auth = inject(AuthenticationService);
        return computed(() => !auth.isAuthenticated() || !auth.user()?.customerNumbers?.length);
      },
    }),
    {
      provide: REQUEST_QUOTE_MESSAGE_GROUP_ID,
      useValue: '30A88221-71C4-4326-A802-7FDFC3496825',
    },
  ];
}

export function provideLegacyCartFeature(): Provider {
  return [
    provideNavItems(
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/carts',
        label: signal($localize`:@@MainMenu_SavedCartsTitle:Saved Carts`),
        icon: signal('add_shopping_cart'),
        priority: signal(51),
        command: signal([`/carts`]),
        hidden: () => {
          const auth = inject(AuthenticationService);
          return computed(() => !auth.isAuthenticated());
        },
      },
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/part-lists',
        label: signal($localize`:@@MainMenu_PartListsTitle:Part Lists`),
        icon: signal('list_alt'),
        priority: signal(50),
        command: signal([`/part-lists`]),
        hidden: () => {
          const auth = inject(AuthenticationService);
          return computed(() => !auth.isAuthenticated());
        },
      },
    ),
  ];
}
