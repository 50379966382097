import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { NoCustomerNumberErrorDetails } from 'authentication-data';
import { ErrorCardComponent, ErrorHandlerBaseComponent, ErrorJsonComponent, ErrorMessageComponent, ErrorTitleComponent } from 'error-ui';

@Component({
  selector: 'ideal-no-customer-number-error-handler',
  standalone: true,
  imports: [ErrorCardComponent, ErrorTitleComponent, ErrorMessageComponent, ErrorJsonComponent, MatButtonModule, RouterModule],
  templateUrl: './no-customer-number-error-handler.component.html',
  styleUrl: './no-customer-number-error-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoCustomerNumberErrorHandlerComponent extends ErrorHandlerBaseComponent<NoCustomerNumberErrorDetails> {}
