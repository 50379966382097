import { CdkMenuModule } from '@angular/cdk/menu';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonBarComponent, ButtonBarItemDirective, ButtonBarLocation } from 'common-ui';

@Component({
  selector: 'ideal-button-bar-page',
  standalone: true,
  imports: [ButtonBarComponent, ButtonBarItemDirective, CdkMenuModule],
  templateUrl: './button-bar.component.html',
  styleUrl: './button-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBarPageComponent {
  protected readonly ButtonBarLocation = ButtonBarLocation;
  protected someValue: number = 0;
}
