import { APP_INITIALIZER, Provider, inject } from '@angular/core';
import { LogRocketConfig } from './LogRocketConfig';
import { LogRocketService } from './logrocket.service';

export function provideLogRocket(cfg?: Partial<LogRocketConfig>): Provider {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const service = inject(LogRocketService);
      return () => service.initialize(cfg);
    },
  };
}
