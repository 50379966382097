import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, PLATFORM_ID, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { AppBreakpointService } from 'app-shell-data';
import { RenderInAppActionsSecondaryDirective } from 'app-shell-ui';
import { AuthenticationService, USER_PROFILE_ROUTE_PATH } from 'authentication-data';
import { AppDimension, LottieComponent } from 'common-ui';
import { CustomerService } from 'lib-customer';

@Component({
  selector: 'ideal-auth-header-button',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    RenderInAppActionsSecondaryDirective,
    LottieComponent,
    RouterModule,
    MatSelectModule,
    AsyncPipe,
    MatMenuModule,
    MatRadioModule,
  ],
  templateUrl: './auth-header-button.component.html',
  styleUrl: './auth-header-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.state]': 'state()',
  },
})
export class AuthHeaderButtonComponent {
  private readonly _authService = inject(AuthenticationService);
  private readonly _customerService = inject(CustomerService);
  private readonly _appBreakpointService = inject(AppBreakpointService);

  protected readonly AppDimension = AppDimension;
  protected readonly userProfilePath = inject(USER_PROFILE_ROUTE_PATH, { optional: true });
  protected readonly isAuthenticated = this._authService.isAuthenticated;
  protected readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  // protected readonly selectedCustomerNumber$ = this._customerService.customerNumberChange;
  protected readonly selectedCustomerItem = toSignal(this._customerService.customerNumberChange);
  protected readonly selectedCustomerNumber = computed(() => this.selectedCustomerItem()?.customerNumber);
  protected readonly selectedCustomerName = computed(() => this.selectedCustomerItem()?.customerName);
  protected readonly customerNumbers = this._customerService.availableCustomerNumbersChange;
  protected readonly customerName = computed(() => this._authService.user()?.name);
  protected readonly customerEmail = computed(() => this._authService.user()?.email);

  protected horizontalSize = computed(() => this._appBreakpointService.appDimensions().width);

  protected readonly state = computed(() => {
    if (!this.isAuthenticated()) {
      return 'sign-in';
    }
    if (this.userProfilePath) {
      return 'profile';
    }
    return 'sign-out';
  });

  protected onClicked(): void {
    if (this.isAuthenticated()) {
      this._authService.signOut();
    } else {
      this._authService.signIn();
    }
  }

  protected onSelectAccount(customerNumber: string): void {
    this._customerService.setSelectedCustomerNumber(customerNumber);
  }
}
