import { Provider } from '@angular/core';
import { provideRoutes } from 'routing-util';
import { AccountManagementFeatureRoutesConfig } from './AccountManagementFeatureRoutesConfig';
import { defaultAccountManagementFeatureRoutesConfig } from './defaultAccountManagementFeatureRoutesConfig';
import { USER_PROFILE_ROUTE_PATH } from 'authentication-data';
import { MY_ACCOUNT_ROUTE_PATH } from 'account-management-data';

export function provideAccountManagementFeatureRoutes(config?: Partial<AccountManagementFeatureRoutesConfig>): Provider {
  const useConfig = { ...defaultAccountManagementFeatureRoutesConfig, ...config };
  return [
    {
      provide: MY_ACCOUNT_ROUTE_PATH,
      useValue: useConfig.path,
    },
    {
      provide: USER_PROFILE_ROUTE_PATH,
      useValue: useConfig.path,
    },
    provideRoutes([
      {
        path: useConfig.path,
        loadChildren: () => import('./routes/routes').then((m) => m.routes),
        data: {
          navigationId: 'app.main-menu/my-account',
        },
      },
    ]),
  ];
}
