import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthenticationService } from 'authentication-data';

@Component({
  selector: 'ideal-login-page',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
  constructor() {
    inject(AuthenticationService).signInRedirect();
  }
}
