import { inject, isDevMode, Provider, signal } from '@angular/core';
import { provideVendorsFeatureRoutes } from './vendors-feature.routes';
import { NavItemType, provideNavItems } from 'navigation-data';
import { VENDORS_ROUTE_PATH } from 'vendors-data';

export function provideVendorsFeature(): Provider {
  return [
    provideVendorsFeatureRoutes(),
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/vendors',
      label: signal($localize`:@@menu.vendors.label:Vendors`),
      icon: signal('storefront'),
      priority: signal(80),
      command: () => signal(['/', inject(VENDORS_ROUTE_PATH)]),
    }),
  ];
}
