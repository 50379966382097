import { inject, isDevMode, Provider, signal } from '@angular/core';
import { NavItemType, provideNavItems } from 'navigation-data';
import { PRODUCT_PROMOTIONS_ROUTE_PATH } from 'product-promotions-data';
import { provideProductPromotionsFeatureRoutes } from './product-promotions-feature.routes';
import { defaultProductPromotionsFeatureConfig, ProductPromotionsFeatureConfig } from './ProductPromotionsFeatureConfig';

export function provideProductPromotionsFeature(config?: Partial<ProductPromotionsFeatureConfig>): Provider {
  const useConfig = { ...defaultProductPromotionsFeatureConfig, ...config };

  return [
    provideProductPromotionsFeatureRoutes(config?.routes),
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/product-promotions',
      label: signal($localize`:@@menu.product-promotions.account.label:Promotions 2`),
      icon: signal('sell'),
      command: () => signal(['/', inject(PRODUCT_PROMOTIONS_ROUTE_PATH)]),
      hidden: signal(!isDevMode()),
    }),
  ];
}
