<ideal-button-bar orientation="vertical">
  <!-- <button *idealButtonBarItem="someValue" (click)="someValue = (someValue + 1) % 5">0</button> -->
  <button *idealButtonBarItem="1">1</button>
  <button *idealButtonBarItem="2">2</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">3</button>
  <button *idealButtonBarItem="3">99999</button>

  <button *idealButtonBarItem="0; where: ButtonBarLocation.End">7</button>

  <button *idealButtonBarItem="7; where: ButtonBarLocation.More">ASDF1</button>
  <button *idealButtonBarItem="7; where: ButtonBarLocation.More">ASDF2</button>
  <button *idealButtonBarItem="7; where: ButtonBarLocation.More">ASDF3</button>
  <button *idealButtonBarItem="7; where: ButtonBarLocation.More">ASDF4</button>
  <button *idealButtonBarItem="7; where: ButtonBarLocation.More">ASDF5</button>

  <!-- <ng-template #moreButton>
    <button>MORE!</button>
  </ng-template> -->
</ideal-button-bar>

<ng-template #file>
  <div cdkMenu>
    <button cdkMenuItem>4qq</button>
    <button cdkMenuItem>fgdf</button>
  </div>
</ng-template>
<br />
<br />
<br />
<br />

<!-- <ideal-button-bar orientation="vertical">
  <button *idealButtonBarItem>0</button>
  <button *idealButtonBarItem="'end'; priority: 5">1</button>
  <button *idealButtonBarItem>2</button>
  <button *idealButtonBarItem>3</button>
  <button *idealButtonBarItem="'start'; priority: -2">4</button>
  <button *idealButtonBarItem>5</button>
  <button *idealButtonBarItem>6</button>
  <button *idealButtonBarItem>7</button>
  <button *idealButtonBarItem>8</button>
  <button *idealButtonBarItem>9</button>
</ideal-button-bar> -->
