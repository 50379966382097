import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import { StockLocationType } from '@idealsupply/ngclient-webservice-inventory';
import { ImageComponent, WithLoadingPlaceholderDirective } from 'common-ui';
import { ProductInventoryStockLocationStore, ProductQuickSearchRecord } from 'product-quick-search-data';

const StockLocationTypeLocalized = {
  ['']: $localize`:@@product-fast-search.location.available-to-order:Available to Order`,
  [StockLocationType.InStock]: $localize`:@@product-fast-search.location.in-stock:In Stock`,
  [StockLocationType.InWarehouse]: $localize`:@@product-fast-search.location.in-warehouse:In Stock`,
  [StockLocationType.OnBackOrder]: $localize`:@@product-fast-search.location.on-back-order:On Back Order`,
};

@Component({
  selector: 'ideal-product-quick-search-item',
  standalone: true,
  imports: [ImageComponent, WithLoadingPlaceholderDirective, JsonPipe /*RouterModule, MatButtonModule */],
  templateUrl: './product-quick-search-item.component.html',
  styleUrl: './product-quick-search-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductQuickSearchItemComponent {
  protected readonly StockLocationType = StockLocationType;
  private _stockLocationStore = inject(ProductInventoryStockLocationStore);
  public readonly product = input.required<ProductQuickSearchRecord>();
  public readonly searchTerm = input.required<string>();
  public readonly resultType = input.required<string>();

  constructor() {
    effect(() => {
      const product = this.product();
      this._stockLocationStore.loadStockLocation(product);
    });
  }
  protected readonly imageSrc = computed(() => this.product().imageUrl?.trim() || undefined);

  protected readonly stock = computed(() => {
    const stock = this._stockLocationStore.getStockLocation(this.product())();
    if (!stock?.update) {
      return null;
    }
    return stock.data.stockLocation ?? '';
  });

  protected readonly stockLabel = computed(() => StockLocationTypeLocalized[this.stock() as unknown as StockLocationType] ?? '');

  protected readonly partNumberMatched = computed(() =>
    this.resultType() === 'partNumber'
      ? this.highlightMatchedString(this.searchTerm(), this.product().partNumber)
      : this.product().partNumber,
  );

  protected readonly descriptionMatched = computed(() =>
    this.resultType() === 'description'
      ? this.highlightMatchedString(this.searchTerm(), this.product().description ?? '')
      : this.product().description,
  );

  protected readonly keywordsMatched = computed(() =>
    this.resultType() === 'keyword'
      ? (this.product().matches?.map((match) => this.highlightMatchedString(this.searchTerm(), match)) ?? [])
      : [],
  );
  protected readonly crossRefsMatched = computed(() =>
    this.resultType() === 'cross-reference'
      ? this.product().matches?.map((match) => this.highlightMatchedString(this.searchTerm(), match))
      : [],
  );

  private highlightMatchedString(search: string, matches: string): string {
    const searchFor = search.trim().toLowerCase();
    const searchIn = matches.trim().toLowerCase();

    const idx = searchIn.indexOf(searchFor);
    if (idx === -1) {
      return matches;
    }

    const one = matches.substring(0, idx);
    const two = matches.substring(idx, idx + searchFor.length);
    const three = matches.substring(idx + searchFor.length);
    return `${one}<span class="search-match">${two}</span>${three}`;
  }

  /*
  onImageLoaded(e: Event) {
    //console.log('onImageLoaded', e);
  }

  onImageError(e: Event) {
    //console.log('onImageError', e);
  }

  public makeMatchedString(item: ProductQuickSearchListItem): string {
    const searchFor = this.searchTerm().trim();
    const searchForL = searchFor.toLowerCase();
    const search = item.matchedKeyword || item.partNumber;
    const searchL = search.toLowerCase();
    const idx = searchL.indexOf(searchForL);
    const one = search.substr(0, idx);
    const two = search.substr(idx, searchFor.length);
    const three = search.substr(idx + searchFor.length);
    return `${one}<span class="search-match">${two}</span>${three}`;
  }*/
}
