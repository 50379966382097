import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from 'authentication-data';
import { APP_CONFIG, AppConfig } from 'config-data';
import { LogRocketConfig, WithLogRocketConfig } from './LogRocketConfig';
import logRocket from 'logrocket';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService {
  private readonly authService = inject(AuthenticationService);
  private readonly appConfig = inject<AppConfig & WithLogRocketConfig>(APP_CONFIG);
  private readonly user = this.authService.user;
  private config: LogRocketConfig = this.appConfig.logRocket ?? {};

  get enabled() {
    return !!this.config.key;
  }

  public initialize(cfg: Partial<LogRocketConfig> = {}) {
    this.config = { ...cfg, ...this.config };
    if (this.enabled) {
      logRocket.init(this.config.key!, {
        disableBusyFramesTracker: true,
        release: this.appConfig.appVersion,
        uploadTimeInterval: this.config.uploadInterval || 30000,
      });

      const user = this.user();
      logRocket.startNewSession();
      if (user) {
        logRocket.identify(user.id, {
          name: user.name!,
          email: user.username!,
          customerNumbers: user.customerNumbers.join(','),
        });
      }
    }
  }
}
