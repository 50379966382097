import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ThemeVisComponent } from '@cpangular/ng-material-theme';

@Component({
  selector: 'ideal-theme-page',
  standalone: true,
  imports: [ThemeVisComponent],
  templateUrl: './theme.component.html',
  styleUrl: './theme.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemePageComponent {}
