import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ErrorService } from 'error-data';
import { OneColumnLayoutComponent } from 'page-layouts-ui';

@Component({
  selector: 'ideal-error-page',
  standalone: true,
  imports: [OneColumnLayoutComponent, NgComponentOutlet],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  private readonly _errorService = inject(ErrorService);
  protected readonly errorHandlerComponent = this._errorService.errorHandlerComponent;
}
