import { Injectable, Injector, Type, effect, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Product } from '@idealsupply/ngclient-webservice-inventory';
import { AuthenticationService } from 'authentication-data';
import { APP_CONFIG, AppConfig } from 'config-data';
import { ProductComponent } from 'feature-products';
import { CartService } from 'lib-cart';
import { OrdersService } from 'lib-orders';
import { MatomoConfiguration, MatomoRouteTracker, MatomoTracker } from 'ngx-matomo';
import { filter, map } from 'rxjs';
import { WithMatomoConfig } from './MatomoConfig';

@Injectable({
  providedIn: 'root',
})
export class MatomoAnalyticsService {
  private readonly injector = inject(Injector);
  private readonly appConfig = inject<AppConfig & WithMatomoConfig>(APP_CONFIG);
  private readonly matomoTracker = inject(MatomoTracker);
  private readonly authService = inject(AuthenticationService);
  private readonly cartService = inject(CartService);
  private readonly orderService = inject(OrdersService);
  private readonly router = inject(Router);

  private readonly config?: MatomoConfiguration = this.appConfig.matomo;

  private readonly url = toSignal(
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((evt) => (evt as NavigationEnd).urlAfterRedirects),
      takeUntilDestroyed(),
    ),
  );

  private readonly user = this.authService.user;
  private readonly cartItemUpdate = toSignal(this.cartService.itemUpdate$);
  private readonly cartClearItems = toSignal(this.cartService.cartClear$);
  private readonly order = toSignal(this.orderService.orderCompleted$);

  private get enabled() {
    return !!this.config && !!this.matomoTracker;
  }

  private checkForProductView() {
    const snapshot = this.router.routerState.snapshot.root;
    const productDetailRoute = this.findRouteComponent(snapshot, ProductComponent);
    if (productDetailRoute) {
      const product = productDetailRoute.data['product'] as Product;
      this.matomoTracker.setEcommerceView(product.lineCode + product.partNumber, product.description, '', product.pricing[0].value);
    }
  }

  private findRouteComponent<T extends Type<any>>(route: ActivatedRouteSnapshot, componentType: T): ActivatedRouteSnapshot | undefined {
    if (route.component === componentType) {
      return route;
    }

    if (route.children.length) {
      for (const child of route.children) {
        const found = this.findRouteComponent(child, componentType);
        if (found != undefined) {
          return found;
        }
      }
    }

    return undefined;
  }

  constructor() {
    if (this.enabled) {
      this.matomoTracker.enableLinkTracking();
      this.matomoTracker.enableHeartBeatTimer(10);
      if (this.config?.routeTracking?.enable === true) {
        this.injector.get(MatomoRouteTracker).startTracking();
      }
      const user = this.user();
      this.matomoTracker.resetUserId();
      if (user) {
        this.matomoTracker.setUserId(user.id);
      }
      effect(() => {
        const url = this.url();
        if (url !== undefined) {
          this.matomoTracker.setCustomUrl(url);
          this.matomoTracker.enableLinkTracking(true);
          this.matomoTracker.trackPageView();
          this.checkForProductView();
        }
      });
      effect(() => {
        const cartItemUpdate = this.cartItemUpdate();
        if (cartItemUpdate) {
          this.matomoTracker.addEcommerceItem(
            cartItemUpdate.lineCode + cartItemUpdate.partNumber,
            cartItemUpdate.item.description,
            undefined,
            cartItemUpdate.price,
            cartItemUpdate.quantity,
          );
          this.matomoTracker.trackEcommerceCartUpdate(this.cartService.subtotal);
        }
      });
      effect(() => {
        const cartClearItems = this.cartClearItems();
        if (cartClearItems) {
          this.matomoTracker.clearEcommerceCart();
          this.matomoTracker.trackEcommerceCartUpdate(0);
        }
      });
      effect(() => {
        const order = this.order();
        if (order) {
          this.matomoTracker.trackEcommerceOrder(order.id, order.orderTotal!, order.subTotal!, order.taxes!);
        }
      });
    }
  }
}
