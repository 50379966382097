import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { Provider, inject } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import {
  IsActiveMatchOptions,
  Router,
  RouterFeatures,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withInMemoryScrolling,
  withNavigationErrorHandler,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { ErrorService } from 'error-data';
import { provideErrorFeature } from 'error-feature';
import { provideErrorStateMatcher } from 'forms-data';
import { provideRoutes } from 'routing-util';
import { AppShellFeatureConfig } from './AppShellFeatureConfig';

const DEFAULT_CONFIG: AppShellFeatureConfig = {
  routes: [],
  enableDebugTracing: false,
};

export function provideAppShellFeature(config?: Partial<AppShellFeatureConfig>): Provider {
  const useConfig = { ...DEFAULT_CONFIG, ...config };

  const routerArgs: RouterFeatures[] = [
    withRouterConfig({
      urlUpdateStrategy: 'eager',
      paramsInheritanceStrategy: 'always',
    }),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'disabled',
    }),
    withViewTransitions({
      skipInitialTransition: true,
      onViewTransitionCreated: ({ transition }) => {
        const router = inject(Router);
        const targetUrl = router.getCurrentNavigation()!.finalUrl!;
        // Skip the transition if the only thing
        // changing is the fragment and queryParams
        const config: IsActiveMatchOptions = {
          paths: 'exact',
          matrixParams: 'exact',
          fragment: 'ignored',
          queryParams: 'ignored',
        };

        if (router.isActive(targetUrl, config)) {
          transition.skipTransition();
        }
      },
    }),
    withComponentInputBinding(),
    withNavigationErrorHandler((err) => inject(ErrorService).handleError(err)),
  ];

  if (useConfig.enableDebugTracing) {
    routerArgs.push(withDebugTracing());
  }

  const p: Provider = [
    provideAnimations(),
    provideRoutes(useConfig.routes),
    provideErrorStateMatcher(),
    provideErrorFeature(),
    provideRouter([], ...routerArgs),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
  ];

  return p;
}
