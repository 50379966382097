@let byPartNumberResults = byPartNumber();
@let byCrossReferenceResults = byCrossReference();
@let byKeywordResults = byKeyword();
@let byDescriptionResults = byDescription();

<ng-container
  *ngTemplateOutlet="SearchHeader; context: { label: 'By part number', count: byPartNumberResults.length, loading: byPartNumberLoading() }"
></ng-container>

<ng-container *ngTemplateOutlet="SearchResults; context: { results: byPartNumberResults, type: 'partNumber' }"></ng-container>

@if (hasCrossReferences()) {
  <ng-container
    *ngTemplateOutlet="
      SearchHeader;
      context: { label: 'By cross reference', count: byCrossReferenceResults.length, loading: byCrossReferenceLoading() }
    "
  ></ng-container>
  <ng-container *ngTemplateOutlet="SearchResults; context: { results: byCrossReferenceResults, type: 'crossReference' }"></ng-container>
}

<ng-container
  *ngTemplateOutlet="SearchHeader; context: { label: 'By keyword', count: byKeywordResults.length, loading: byKeywordLoading() }"
></ng-container>

<ng-container *ngTemplateOutlet="SearchResults; context: { results: byKeywordResults, type: 'keyword' }"></ng-container>

<ng-container
  *ngTemplateOutlet="
    SearchHeader;
    context: { label: 'By description', count: byDescriptionResults.length, loading: byDescriptionLoading() }
  "
></ng-container>

<ng-container *ngTemplateOutlet="SearchResults; context: { results: byDescriptionResults, type: 'description' }"></ng-container>

<ng-template #SearchHeader let-label="label" let-count="count" let-loading="loading">
  <div search-header>
    <label>{{ label }}</label>
    @if (loading) {
      <mat-spinner mode="indeterminate" [diameter]="24"></mat-spinner>
    } @else if (count === 0) {
      <div no-results>No results</div>
    }
  </div>
</ng-template>

<ng-template #SearchResults let-results="results" let-resultType="type">
  <mat-nav-list>
    @for (item of results; track $index) {
      @if (item) {
        <a mat-button mat-list-item [routerLink]="['/products', item.lineCode + item.partNumber]">
          <ideal-product-quick-search-item
            [product]="item"
            [searchTerm]="searchTerm()"
            [resultType]="resultType"
          ></ideal-product-quick-search-item>
        </a>
      }
    }
  </mat-nav-list>
</ng-template>
