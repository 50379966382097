import { Routes } from '@angular/router';
import { ButtonBarPageComponent } from './pages/button-bar/button-bar.component';
import { ThemePageComponent } from './pages/theme/theme.component';
import { ComponentsPageComponent } from './pages/components/components.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'products',
    pathMatch: 'full',
  },
  {
    path: 'button-bar',
    component: ButtonBarPageComponent,
  },
  {
    path: 'components',
    component: ComponentsPageComponent,
  },
  {
    path: 'theme',
    component: ThemePageComponent,
  },
];
