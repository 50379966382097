import { inject, Provider } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationError, Router, RouterStateSnapshot } from '@angular/router';
import {
  BasicErrorDetails,
  ERROR_DIALOG_COMPONENT,
  ERROR_ROUTE_PATH,
  ErrorDetails,
  ErrorService,
  HttpErrorDetails,
  NavigationErrorDetails,
  PageNotFoundErrorDetails,
  PermissionErrorDetails,
  provideDefaultErrorHandlerComponent,
  provideErrorComponentHandlers,
} from 'error-data';
import {
  BasicErrorHandlerComponent,
  DefaultErrorHandlerComponent,
  HttpErrorHandlerComponent,
  NavigationErrorHandlerComponent,
  PageNotFoundErrorHandlerComponent,
  PermissionErrorHandlerComponent,
  UnknownErrorHandlerComponent,
} from 'error-ui';
import { provideRoutes } from 'routing-util';
import { ErrorFeatureConfig } from './ErrorFeatureConfig';
import { ErrorPageComponent } from './routes/error-page/error-page.component';
import { ErrorDialogComponent } from 'error-ui';

const DEFAULT_FEATURE_CONFIG: ErrorFeatureConfig = {
  path: 'error',
};

export function provideErrorFeature(config?: Partial<ErrorFeatureConfig>): Provider {
  const useConfig = { ...DEFAULT_FEATURE_CONFIG, ...config };
  return [
    {
      provide: ERROR_ROUTE_PATH,
      useValue: useConfig.path,
    },
    {
      provide: ERROR_DIALOG_COMPONENT,
      useValue: ErrorDialogComponent,
    },
    provideDefaultErrorHandlerComponent(DefaultErrorHandlerComponent),
    provideErrorComponentHandlers(
      {
        handles: (error) => error instanceof PageNotFoundErrorDetails,
        component: PageNotFoundErrorHandlerComponent,
      },
      {
        handles: (error) => error instanceof NavigationErrorDetails,
        component: NavigationErrorHandlerComponent,
      },
      {
        handles: (error) => error instanceof HttpErrorDetails,
        component: HttpErrorHandlerComponent,
      },
      {
        handles: (error) => error instanceof PermissionErrorDetails,
        component: PermissionErrorHandlerComponent,
      },
      {
        handles: (error) => error instanceof ErrorDetails,
        component: UnknownErrorHandlerComponent,
      },
      {
        handles: (error) => error instanceof BasicErrorDetails,
        component: BasicErrorHandlerComponent,
      },
    ),
    provideRoutes([
      {
        path: useConfig.path,
        component: ErrorPageComponent,
      },
      {
        path: '**',
        canActivate: [
          (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
            const router = inject(Router);
            const errorService = inject(ErrorService);
            const navError = new NavigationError(router.getCurrentNavigation()?.id ?? 1, location.href, null, state);
            const cmd = errorService.handleError(new PageNotFoundErrorDetails(navError), true);
            return cmd;
          },
        ],
        children: [],
      },
    ]),
  ];
}
