import { Provider, computed, inject, signal } from '@angular/core';
import { MY_ACCOUNT_ROUTE_PATH } from 'account-management-data';
import { AuthenticationService } from 'authentication-data';
import { NavItemType, provideNavItems } from 'navigation-data';
import { provideAccountManagementFeatureRoutes } from './account-management-feature.routes';
import { AccountManagementFeatureConfig } from './AccountManagementFeatureConfig';
import { defaultAccountManagementFeatureConfig } from './defaultAccountManagementFeatureConfig';

export function provideAccountManagementFeature(config?: Partial<AccountManagementFeatureConfig>): Provider {
  const useConfig = { ...defaultAccountManagementFeatureConfig, ...config };
  const items: Provider = [
    provideAccountManagementFeatureRoutes(useConfig.routes),
    provideNavItems(
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/my-account',
        label: signal($localize`:@@menu.account-management.account.label:Account`),
        icon: signal('person'),
        heading: signal('bottom'),
        command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}`]),
        hidden: () => {
          const authService = inject(AuthenticationService);
          return computed(() => !authService.isAuthenticated());
        },
      },
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/my-account/profile',
        heading: signal('Profile'),
        label: signal($localize`:@@menu.account-management.profile.label:My Profile`),
        priority: signal(3),
        command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}`]),
      },
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/my-account/edit-profile',
        heading: signal('Profile'),
        label: signal($localize`:@@menu.account-management.edit-profile.label:Edit Profile`),
        priority: signal(2),
        command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}`]),
        args: signal({ fragment: 'edit' }),
        hidden: () => {
          const authService = inject(AuthenticationService);
          return computed(() => !!authService.user()?.adUsername);
        },
      },
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/my-account/password',
        heading: signal('Profile'),
        label: signal($localize`:@@menu.account-management.change-password.label:Change Password`),
        priority: signal(1),
        command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}`]),
        args: signal({ fragment: 'password' }),
        hidden: () => {
          const authService = inject(AuthenticationService);
          return computed(() => !!authService.user()?.adUsername);
        },
      },
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/my-account/email',
        heading: signal('Profile'),
        label: signal($localize`:@@menu.account-management.change-email.label:Change Email`),
        command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}`]),
        args: signal({ fragment: 'email' }),
        hidden: () => {
          const authService = inject(AuthenticationService);
          return computed(() => !!authService.user()?.adUsername);
        },
      },
      {
        type: NavItemType.RouteLink,
        id: 'app.main-menu/my-account/customer-numbers',
        label: signal($localize`:@@menu.account-management.profile.label:Manage Customer Numbers`),
        heading: signal('Accounts'),
        command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}`, `customer-numbers`]),
      },
    ),
  ];

  // if (isDevMode()) {
  //   items.push(
  //     provideNavItems(),
  //     // {
  //     //   type: NavItemType.RouteLink,
  //     //   id: 'app.main-menu/my-account-2',
  //     //   label: signal($localize`:@@menu.account-management.account2.label:Account2`),
  //     //   icon: signal('person'),
  //     //   heading: signal('bottom'),
  //     //   command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}-2`]),
  //     //   hidden: () => {
  //     //     const authService = inject(AuthenticationService);
  //     //     return computed(() => !authService.isAuthenticated());
  //     //   },
  //     // },
  //     // {
  //     //   type: NavItemType.RouteLink,
  //     //   id: 'app.main-menu/my-account-2/profile',
  //     //   heading: signal('Profile'),
  //     //   label: signal($localize`:@@menu.account-management.profile.label:My Profile`),
  //     //   priority: signal(3),
  //     //   command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}-2`]),
  //     // },
  //     // {
  //     //   type: NavItemType.RouteLink,
  //     //   id: 'app.main-menu/my-account-2/edit-profile',
  //     //   heading: signal('Profile'),
  //     //   label: signal($localize`:@@menu.account-management.edit-profile.label:Edit Profile`),
  //     //   priority: signal(2),
  //     //   command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}-2`]),
  //     //   args: signal({ fragment: 'edit' }),
  //     // },
  //     // {
  //     //   type: NavItemType.RouteLink,
  //     //   id: 'app.main-menu/my-account-2/password',
  //     //   heading: signal('Profile'),
  //     //   label: signal($localize`:@@menu.account-management.change-password.label:Change Password`),
  //     //   priority: signal(1),
  //     //   command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}-2`]),
  //     //   args: signal({ fragment: 'password' }),
  //     // },
  //     // {
  //     //   type: NavItemType.RouteLink,
  //     //   id: 'app.main-menu/my-account-2/email',
  //     //   heading: signal('Profile'),
  //     //   label: signal($localize`:@@menu.account-management.change-email.label:Change Email`),
  //     //   command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}-2`]),
  //     //   args: signal({ fragment: 'email' }),
  //     // },
  //     // {
  //     //   type: NavItemType.RouteLink,
  //     //   id: 'app.main-menu/my-account-2/customer-numbers',
  //     //   label: signal($localize`:@@menu.account-management.profile4.label:Manage Customer Numbers`),
  //     //   heading: signal('Accounts'),
  //     //   command: () => signal([`/${inject(MY_ACCOUNT_ROUTE_PATH)}-2`, `customer-numbers`]),
  //     // },
  //   );
  // }
  return items;
}
