import { Provider } from '@angular/core';
import { Routes, ROUTES } from '@angular/router';

export function provideRoutes(routes: Routes): Provider {
  return {
    provide: ROUTES,
    useValue: routes,
    multi: true,
  };
}
