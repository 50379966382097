import { ApplicationConfig, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAccountManagementFeature } from 'account-management-feature';
import { provideAnalyticsFeature } from 'analytics-feature';
import { provideAuthenticationFeature } from 'authentication-feature';
import { provideCartFeature } from 'cart-feature';
import { provideScriptLoader } from 'common-util';
import { provideGoogleApiKey } from 'google-data';
import { provideImportLegacyFeatures, provideLegacyLoadingIndicatorFeature } from 'import-legacy-feature';
import { provideLogRocket } from 'log-rocket-data';
import { provideNavigationFeature } from 'navigation-feature';
import { provideProductQuickSearchFeature } from 'product-quick-search-feature';

import { routes } from './app.routes';
import { provideProductPromotionsFeature } from 'product-promotions-feature';
import { provideAppShellFeature } from 'app-shell-feature';
import { provideVendorsFeature } from 'vendors-feature';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CAD',
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },

    provideScriptLoader(
      $localize`:@@password-strength.locale-file.url:https://cdn.jsdelivr.net/npm/@zxcvbn-ts/language-en@2.0.0/dist/zxcvbn-ts.js`,
    ),
    provideGoogleApiKey('AIzaSyCb3E0trO1zCp_vNOiDGCa5qSRt99QJNdw'),
    provideAuthenticationFeature(),
    provideNavigationFeature(),
    provideProductQuickSearchFeature(),

    provideAccountManagementFeature(),
    provideProductPromotionsFeature(),
    provideVendorsFeature(),

    provideCartFeature(),
    provideLegacyLoadingIndicatorFeature(),
    provideAnalyticsFeature(),
    provideLogRocket(),
    provideImportLegacyFeatures(),
    provideClientHydration(),
    provideAppShellFeature({ routes: routes, enableDebugTracing: false }),
  ],
};
