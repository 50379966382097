import { Provider } from '@angular/core';
import { SIGN_IN_CALLBACK_PATH, SIGN_IN_ROUTE_PATH, SIGN_OUT_ROUTE_PATH } from 'authentication-data';
import { provideRoutes } from 'routing-util';
import { AuthenticationFeatureRoutesConfig } from './AuthenticationFeatureRoutesConfig';
import { defaultAuthenticationFeatureRoutesConfig } from './defaultAuthenticationFeatureRoutesConfig';
import { LoginPageComponent } from './routes/login/login.component';
import { LogoutPageComponent } from './routes/logout/logout.component';
import { OidcCallbackPageComponent } from './routes/oidc-callback/oidc-callback.component';

export function provideAuthenticationFeatureRoutes(config?: Partial<AuthenticationFeatureRoutesConfig>): Provider {
  const useConfig = { ...defaultAuthenticationFeatureRoutesConfig, ...config };
  return [
    {
      provide: SIGN_IN_ROUTE_PATH,
      useValue: useConfig.signInPath,
    },
    {
      provide: SIGN_OUT_ROUTE_PATH,
      useValue: useConfig.signOutPath,
    },
    provideRoutes([
      { path: SIGN_IN_CALLBACK_PATH, component: OidcCallbackPageComponent },
      { path: useConfig.signInPath, component: LoginPageComponent },
      { path: useConfig.signOutPath, component: LogoutPageComponent },
    ]),
  ];
}
