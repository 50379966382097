<div class="header-widget auth-header-button" *renderInAppActionsSecondary>
  @defer {
    @switch (state()) {
      @case ("profile") {
        <button class="menu" mat-button [matMenuTriggerFor]="menu">
          <mat-icon class="material-symbols-outlined">passkey</mat-icon>
          <div class="label">
            @if (horizontalSize() > AppDimension.md) {
              <div class="account-name">
                <label>{{ customerName() }}</label>
              </div>
            }
            @if (horizontalSize() > AppDimension.xs) {
              <div class="account-number" [class.right]="!selectedCustomerNumber()">
                @if (selectedCustomerNumber()) {
                  <label>
                    {{ selectedCustomerNumber() }}
                  </label>
                }
                @if (selectedCustomerName()) {
                  <label>{{ selectedCustomerName() }}</label>
                }
                <mat-icon>arrow_drop_down</mat-icon>
              </div>
            }
          </div>
        </button>

        <mat-menu #menu="matMenu" panelClass="account-menu" classList="account-menu">
          <label> Logged in as </label>
          <div class="field account-name">{{ customerName() }}</div>
          <div class="field account-email">{{ customerEmail() }}</div>
          @if ((customerNumbers | async)?.length) {
            <label> Active Account </label>
            <mat-radio-group [value]="selectedCustomerNumber()" (change)="onSelectAccount($event.value)">
              @for (cNumb of customerNumbers | async; track cNumb.customerNumber) {
                <mat-radio-button [value]="cNumb.customerNumber"
                  >{{ cNumb.customerNumber }}
                  @if (cNumb.customerName) {
                    - {{ cNumb.customerName }}
                  }
                </mat-radio-button>
              }
            </mat-radio-group>
          }
          <label> Actions </label>
          <a class="account" mat-menu-item [routerLink]="['/' + userProfilePath]" routerLinkActive="active"
            >Account Settings<mat-icon>person</mat-icon></a
          >
          <button class="account" mat-menu-item (click)="onClicked()" color="warn">Logout<mat-icon>logout</mat-icon></button>
        </mat-menu>
      }
      @case ("sign-out") {
        <button class="btn" mat-icon-button (click)="onClicked()">
          <mat-icon class="material-symbols-outlined">{{ isAuthenticated() ? "person" : "key" }}</mat-icon>
        </button>
      }
      @default {
        <button class="btn" mat-button (click)="onClicked()">
          <mat-icon class="material-symbols-outlined">passkey</mat-icon>
          @if (horizontalSize() > AppDimension.xs) {
            Login
          }
        </button>
      }
    }
  }
</div>
