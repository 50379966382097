import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthenticationService } from 'authentication-data';

@Component({
  selector: 'ideal-logout-page',
  standalone: true,
  imports: [],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutPageComponent {
  constructor() {
    inject(AuthenticationService).signOutRedirect();
  }
}
