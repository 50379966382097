import { Provider } from '@angular/core';
import { AUTHENTICATION_ENABLED, NoCustomerNumberErrorDetails } from 'authentication-data';
import { NoCustomerNumberErrorHandlerComponent } from 'authentication-ui';
import { provideFeatureImportComponents } from 'common-util';
import { provideErrorComponentHandlers } from 'error-data';
import { provideAuthenticationFeatureRoutes } from './authentication-feature.routes';
import { AuthenticationFeatureRoutesConfig } from './AuthenticationFeatureRoutesConfig';
import { AuthHeaderButtonComponent } from './components';

export interface AuthenticationFeatureConfig {
  routes?: AuthenticationFeatureRoutesConfig;
}

const DEFAULT_FEATURE_CONFIG: AuthenticationFeatureConfig = {};

export function provideAuthenticationFeature(config?: Partial<AuthenticationFeatureConfig>): Provider {
  const useConfig = { ...DEFAULT_FEATURE_CONFIG, ...config };
  return [
    {
      provide: AUTHENTICATION_ENABLED,
      useValue: true,
    },
    provideFeatureImportComponents(AuthHeaderButtonComponent),
    provideAuthenticationFeatureRoutes(useConfig.routes),
    provideErrorComponentHandlers({
      handles: (error) => error instanceof NoCustomerNumberErrorDetails,
      component: NoCustomerNumberErrorHandlerComponent,
    }),
  ];
}
